"use client";

import { useContext, createContext } from "react";

import { SettingsContextProps } from "../types";

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
// IMPORTANTE: These types are only copied from the original file to encapsulation the widget
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

interface ApiKeyDoc {
  key: string;
  name: string;
  organizationId?: string;
  createdAt: any; // firestore.Timestamp
  ownerUid: string; // relation to user
  id: string; // same as doc id
}

interface WidgetUiSettings {
  name: string;
  description: string;
  chatPersistence: "never" | "tabClosed";
  position: "left" | "right";
  sideSpacing: number;
  bottomSpacing: number;
  poweredBy?: boolean;
  mainColor: string;
  laucherBgColor: string;
  launcherIcon: File | null;
  assistantAvatar: File | null;
  startMessage: string;
  showStartMessage: boolean;
  inputPlaceholder: string;
  aiResponseText: string;
  uniqueSellingProposition: string;
  productInformation: string;
  userApiKey: string;
  assistant: string;
  informationToCapture: string[];
  userActions: string;
  selectedAssistant?: {
    name?: string;
    group: string;
    id: string;
  } | null;

  widgetId?: string;
  ownerUid?: string;
  teamId?: string;

  openai?: {
    assistantId: string;
    name?: string;
    apiKeyId: string;
    description?: string;
    model?: string;
    instructions?: string;
    files?: {
      id: string;
      name: string;
    }[];
    capabilities?: any[];
    functionSchemas?: {
      id: string;
      schema: string;
      apiKeyHeader?: string | "Bearer" | "Basic";
    }[];
  };
}

// ----------------------------------------------------------------------

export interface WidgetSettings extends WidgetUiSettings {
  plan: string | null;
  errorEmptyAiParameters: boolean;
  apiKeyId?: string;
  poweredBy?: boolean;
}

export const WidgetSettingsContext = createContext(
  {} as SettingsContextProps &
    WidgetSettings & {
      setSettings: <K extends keyof WidgetSettings>(field: K, value: WidgetSettings[K]) => void;
      resetDefaultUISettings: () => void;
    }
);

export const useWidgetSettingsContext = () => {
  const context = useContext(WidgetSettingsContext);

  if (!context) throw new Error("useSettingsContext must be use inside SettingsProvider ");

  return context;
};
